/* Define App firebase config here */
  var firebaseAppConfig = {
    apiKey: "AIzaSyCcJGqlNIlcgpAcN9CZwg1RSdeBS60yj_M",
    authDomain: "admin.justdrinks.app",
    databaseURL: "https://fiesta-drinks.firebaseio.com",
    projectId: "fiesta-drinks",
    storageBucket: "fiesta-drinks.appspot.com",
    messagingSenderId: "737865600208",
    appId: "1:737865600208:web:5ef34b39bc1628011ae2d5",
    measurementId: "G-XJJW222VKW"
  };
export {firebaseAppConfig}
