<Page name="home" on:pageInit={onPageInit}>
  <!-- Top Navbar -->
  <Navbar large sliding={false}>
    <NavLeft>
      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
    </NavLeft>
    <NavTitle sliding>Just Drinks Admin</NavTitle>
    <NavTitleLarge>Just Drinks Admin</NavTitleLarge>
  </Navbar>
  <!-- Page content -->
<BlockTitle>Locations</BlockTitle>
<List mediaList>
  {#each locs as l,index}
    <ListItem title={l.data.name} swipeout data-id={l.id} on:click={()=>clickLoc(index)}  onSwipeoutDelete={(e) => onDeleted(index)}>
     <SwipeoutActions right>
        <SwipeoutButton delete confirmText="Are you sure you want to delete this location?">Delete</SwipeoutButton>
     </SwipeoutActions>
      <div slot="media">
        {#if l.data.pic}
          <img src={'https://img.imageboss.me/justdrinks/height/80/venue/' + l.data.pic} width="80" alt="" />
        {/if}
      </div>
    </ListItem>
  {/each}
</List>

  


</Page>
<script>
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
import {getNearbyPlaces } from '../js/core'
import {place} from '../js/store'
import {user} from '../components/dts-shared-components/auth/store'

let locs=[];

function clickLoc(i){
  let l=locs[i];
  place.set(l);
 f7.views.current.router.navigate('/detail/loc/' + l.id + '/');
}
function onDeleted(idx){
  let item=locs[idx];
  $firestoreInstance.collection(`locations`).doc(item.id).delete()
  .then(()=>{
      console.log("doc removed")
  })
}
function getLocations(){
  getNearbyPlaces($firestoreInstance,34.2145218,-118.4533285,1000,50)
  .then((d)=>{
     locs=d;
  })
  /*
  $firestoreInstance.collection("locations")
  .get()
  .then((qs)=>{
    let docs=[];
    qs.forEach((doc)=>{
      let data=doc.data();
      data.id=doc.id;
      docs.push(data)
    })
    return docs;
     
  })
  .then((docs)=>{
    locs=docs;
  })
  */
}

function onPageInit(){
  getLocations();
  console.log($user)
}

  import {
    f7,
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,SwipeoutActions,SwipeoutButton
  } from 'framework7-svelte';
</script>