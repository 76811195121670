
import HomePage from '../pages/home.svelte';
import AboutPage from '../pages/about.svelte';
import DetailPage from '../pages/detail.svelte';

import MorePage from '../pages/more.svelte';
import WinePage from '../pages/wine.svelte';
import FormPage from '../pages/form.svelte';
import AlcoholPage from '../pages/alcohol.svelte';
import LeftPage1 from '../pages/left-page-1.svelte';
import LeftPage2 from '../pages/left-page-2.svelte';
import DynamicRoutePage from '../pages/dynamic-route.svelte';
import RequestAndLoad from '../pages/request-and-load.svelte';
import NotFoundPage from '../pages/404.svelte';
import {handleAsyncLogin } from '../components/dts-shared-components/auth/core'

var routes = [
  {
    path: '/',
    name: 'home',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
        var app = router.app;      
      //   console.log("qr aync, route to");
      handleAsyncLogin(app,true)
      .then(()=>{
        resolve({component: HomePage})
      },()=>{
        reject();
      })
    }
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/wine/loc/:id/',
    name: 'wine',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
        var app = router.app;      
      //   console.log("qr aync, route to");
      handleAsyncLogin(app,true)
      .then(()=>{
        resolve({component: WinePage})
      },()=>{
        reject();
      })
    }
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/alcohol/',
    component: AlcoholPage,
  },
  {
    path: '/detail/loc/:id/',
    component: DetailPage,
  },
  {
    path: '/left-page-1/',
    component: LeftPage1,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
