 <Block>
    {#if brands.length}
        {#each brands as b,i}
            <Chip on:click={() => clickChip(i)} outline={!b.checked} id={b.id} >{b.name}</Chip>
        {/each}
    {:else}
        No {bname} products were found. Please check with admin team.
    {/if}
</Block>
<script>
export let atype;
export let lid;

let dref;
let locdata=[];
 
export let bname="Tequila";


let brands=[]
  import { onMount } from 'svelte';
import { f7, f7ready, Page, Navbar, BlockTitle, Block,Toolbar,Link,List,ListItem,Row,Col,Chip } from 'framework7-svelte';
import {firestoreInstance,firebaseAuthLoaded} from './dts-shared-components/firebase/store'



 onMount(() => {
     getBrands(atype).then((r)=>{
         // Add additional brand names
        let blist=[];
        if (r.length > 0){
            r.forEach((b)=> {
            if (atype == 'tequila'){
                 blist.push({brand:b.name,name,id: b.id + '-blanco',origId: b.id, name: b.name + ' Blanco', checked: false,subtype: 'blanco'});
                blist.push({brand:b.name,id: b.id + '-reposado',origId: b.id, name: b.name + ' Reposado', checked: false,subtype: 'reposado'});
                blist.push({brand:b.name,id: b.id + '-anejo',origId: b.id, name: b.name + ' Anejo', checked: false,subtype: 'anejo'});
                if (!b.name.includes("Extra Anejo"))
                    blist.push({brand:b.name,id: b.id + '-exanejo',origId: b.id, name: b.name + ' Extra Anejo', checked: false,subtype: 'extra-anejo'});

            }else{
                // blist.push({brand:b.name,name,id: b.id,origId: b.id, name: b.name, checked: false});
            }
            if (b.list){
                b.list.forEach((li,sindex)=>{
                    blist.push({brand:b.name,origId: b.id,idx: sindex,id: b.id + '-l' + sindex, name: b.name + ` ${li.name}`, checked: false,subtype: b.type})
                })
            }
        
            })
            dref=$firestoreInstance.collection(`locations/${lid}/alcohol`).doc(atype);
            dref.get().then((tdoc)=>{
                if (tdoc.exists){
                    locdata=tdoc.data();

                    let alist=locdata.list;
                    // Update "checked" items 
                    alist.forEach((li)=>{
                        let fi=blist.findIndex((o)=>{
                            return o.name === li.name
                        })
                        if (fi >= 0){
                            blist[fi].checked=true;
                        }
                    })

                
                }else{
                    dref.set({
                        list: []
                    })
                    locdata={};
                    locdata.list=[];
                }
                brands=blist;
            });
        }
        

     })
    f7ready(() => {
        
    });
  })

function getBrands(type){
//let firestore=$firestoreInstance;

  let q=$firestoreInstance.collection('alcohol-brands').where('type','==',type);
  return q.get().then((qs)=>{
      console.log(qs)
        let tmp=[];
        if (!qs.empty){
            console.log("not empty")
            qs.forEach(function(doc) {
            let data=doc.data();
                // doc.data() is never undefined for query doc snapshots
            tmp.push({ id: doc.id,name: data.name, list:data.list});

            });
        }
        const sorted = tmp.sort((a, b) => a.name - b.name);
        return sorted;
  })

 }

 function clickChip(idx){
    let br=brands[idx];

     //console.log(e);
    brands[idx].checked=!brands[idx].checked;

    let nameToAdd=br.name;

            //if (br.subtype == "blanco") nameToAdd=`${brand.name} Blanco`;
            //else if (br.subtype == "reposado") nameToAdd=`${brand.name} Reposado`;
            //else if (br.subtype == "anejo") nameToAdd=`${brand.name} Anejo`;
            //if (br.idx){
            //    if (brand.list){
            //        let n=brand.list[br.idx].name;
            //        nameToAdd=`${brand.name} ${n}`
            //    }
            //}
    let alist=locdata.list;
    if (alist){
        const found = alist.some(el => el.name === nameToAdd);
        if (!found && br.checked){
            let tmp= {id: br.origId,name: nameToAdd};
            if (br.idx >= 0) tmp.idx=br.idx;
            alist.push(tmp)
            dref.set(locdata,{merge:true});
        }else{
            if (!br.checked && found){
                // remove
                let fi=alist.findIndex((o)=>{
                    return o.name === nameToAdd
                })
                if (fi >= 0){
                    alist.splice(fi,1);
                        dref.set(locdata,{merge:true});
                }
            }
        }
    }
 }

</script>