
import * as geofirestore from 'geofirestore';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
//import i18next from 'i18next';

function handleErrors(response) {
	console.log("got response",response)
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response.json();
}

export async function searchDrinks(type){
    console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/${type}`)
  return window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/${type}`)
  .then(handleErrors)
  .catch(function(ex) {
	  throw Error(ex);
  })
}

export async function getInternalCocktails(db,type){
	let cocktailsRef=db.collection(`cocktails2`);
	return cocktailsRef.doc(type)
	.get()
	.then((doc)=>{
		if (doc.exists){
			return doc.data();
		}else
			return null;
	})
	.then((data)=>{
		return data.list;
	})

}

export async function getLocDoc(db,lid,coll,doc){
	let locDocRef=db.collection(`locations/${lid}/${coll}`);
	return locDocRef.doc(doc)
	.get()
	.then((d)=>{
		if (d.exists) return d.data();
		else return null;
	})
	.catch((e)=>{
		return null;
	})
}
/*
export async function getAlcDoc(db,lid,doc){
	let brandsRef=db.collection(`locations/${lid}/alcohol`);
  
	let tequilaref=brandsRef.doc('tequila');
	let vodkaref=brandsRef.doc('vodka');
	let rumref=brandsRef.doc('rum');
	let ginref=brandsRef.doc('gin');
	let wref=brandsRef.doc('whiskey');
	let bref=brandsRef.doc('brandy');
  
	let doc={
	  tequila:{list:[]},
	  vodka:{list:[]},
	  rum:{list:[]},
	  gin:{list:[]},
	  whiskey:{list:[]},
	  brandy:{list:[]}
	};
	await tequilaref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.tequila=qs.data();
	  }
	})
	await vodkaref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.vodka=qs.data();
	  }
	});
	await rumref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.rum=qs.data();
	  }
	});
	await wref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.whiskey=qs.data();
	  }
	});
	await ginref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.gin=qs.data();
	  }
	});
	await bref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.brandy=qs.data();
	  }
	});

	return doc;
	return window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/${doc}?id=${loc}`)
    .then(function(response) {
        return response.json()
    }).then(function(json) {
        console.log(json)
        return json;
    }).catch(function(ex) {
        console.log("error",ex)
    })
}*/
export function addPlace(firestore,name,address,city,st,pic,lat,lng){
	const GeoFirestore = geofirestore.initializeApp(firestore);
	const locations = GeoFirestore.collection('locations');
	locations.add({
		name: name,
		pic: pic,
		address:address,
		city:city,
		st:st,
		// The coordinates field must be a GeoPoint!
		coordinates: new firebase.firestore.GeoPoint(lat,lng)
	  });

}
export function getNearbyPlaces(firestore,lat,lng,radius,limit){
    if (!radius) radius=5.0
    if (!limit) limit=10
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const locations = GeoFirestore.collection('locations');
    return new Promise( (resolve,reject) => {
        const query = locations.near({ center: new firebase.firestore.GeoPoint(lat, lng), radius: radius}).limit(limit);
        // Get query (as Promise)
        return query.get().then((value) => {
            // All GeoDocument returned by GeoQuery, like the GeoDocument added above
            const docs=value.docs.map((doc)=>{
                doc['data']=doc['data']();
                return doc;
            })
            if (docs.length > 0){
                const sorted = docs.sort((a, b) => a.distance - b.distance);
                resolve(sorted);
            }else{
                resolve([]);
            }
        })
        .catch(()=>{
            reject();
        })
    });
}

export function getBrands(firestore,type){
	//let firestore=$firestoreInstance;
	
	  let q=firestore.collection('alcohol-brands').where("type", "==", type)
	  return q.get().then((qs)=>{
			let tmp=[];
			qs.forEach(function(doc) {
			  let data=doc.data();
				// doc.data() is never undefined for query doc snapshots
			  tmp.push({ id: doc.id,name: data.name, list:data.list});
	
			});
			const sorted = tmp.sort((a, b) => a.name - b.name);
			return sorted;
	  })
	
}



