import {loginStatus,gotEmailAuthLink,savedEmail,loginRequired} from './store'
let isLoggedIn, gotEmailLink,email,isRequired;


loginStatus.subscribe(value => {
  isLoggedIn = value;
});
gotEmailAuthLink.subscribe(value => {
  gotEmailLink = value;
});
savedEmail.subscribe(value => {
  email = value;
});
loginRequired.subscribe(value => {
  isRequired = value;
});

function emailLinkAuth(auth,email){
     return auth.signInWithEmailLink(email, window.location.href)
    .then(function(result) {
      //let user=result.user;
      window.history.pushState({}, '', "/");
      // f7.views.current.router.navigate('/home/',{reloadCurrent: true,pushState:false});
      //  close();
    })
    .catch(function(error) {
        console.error(error)
      window.history.pushState({}, '', "/");      
      //f7.views.current.router.navigate('/home/',{reloadCurrent:true,pushState:false});
    });
}
 
export function SigninUserEmailLink(email){
  let auth= firebase.auth();    
  let user=auth.currentUser;
  return new Promise(function(resolve, reject) {
    if (user){
        // Chech if user already created as anonymous then convert account
        if (user.isAnonymous){
            let credential = firebase.auth.EmailAuthProvider.credentialWithLink(
            email, window.location.href);
            user.linkWithCredential(credential)
            .then((userCred)=>{
              //var user = usercred.user;
              console.log("Anonymous account successfully upgraded", userCred);
              resolve();
            })
            .catch((e)=>{
              console.log("something went wrong with link credential ****************",e)
              reject();
            })
        }
    }else{
        // Handle new user email auth
        emailLinkAuth(auth,email)
        .then(()=>{
          resolve();
        })
        .catch(()=>{
          reject();
        });
    }
  });
}
export function getUserToken(){
  return firebase.auth().currentUser.getIdToken(false)
  .then(function (token) {
      return token;
  })
  .catch(function (err) {
      console.error(err);
  });
}

export async function loginAnonymously(){
  return firebase.auth().signInAnonymously()
  .then(()=>{
      return firebase.auth().currentUser;
     
  });
}

export function handleAsyncLogin(app,showIndicator){
  //console.log("--handle async login")
  return new Promise(function(resolve, reject) {
    if (isLoggedIn){
      resolve(true);
    }else{
      if (showIndicator)
        app.preloader.show();
      // wait for firestore resoures to load and authenticate, then check auth again
      window.setTimeout(()=>{
        if (showIndicator)
          app.preloader.hide();
        let auth=firebase.auth();
        if (auth.isSignInWithEmailLink(window.location.href)) {
          if (!email){
            app.dialog.prompt(
              'Please enter you email address again.',
              'Confirm',
              function(value) {
                if (value != '') {
                  SigninUserEmailLink(value)
                  .then(()=>{
                    resolve(true);
                  });
                }
              }
            );
          }else{
            SigninUserEmailLink(email)
            .then(()=>{
              savedEmail.set(null);
              window.history.pushState({}, app.name, "/");  
                resolve(true);
              });
          }
        }else{
          if (isLoggedIn){ // We could have an anonymous user
            resolve(true);
          }else{
            // Check for social login result
            auth.getRedirectResult().then(function(result) {
              window.history.pushState({}, app.name, "/");    
              if (result.credential) {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                resolve(true);
                // ...
              }else{
                if (isRequired){
                  app.loginScreen.open('#app-login-screen')
                  reject("error");
                }else{
                  resolve(false);
                }
              }
              // The signed-in user info.
              var user = result.user;
            }).catch(function(error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // The email of the user's account used.
              var email = error.email;
              // The firebase.auth.AuthCredential type that was used.
              var credential = error.credential;
              app.dialog.alert("Oops something went wrong with login. Please try again.");
              reject();
              // ...
            });
          }
        }
      },3800);
    }
  });
}


