<App params={ f7params } themeDark>

  <!-- Left panel with cover effect when hidden -->
  <Panel left cover themeDark visibleBreakpoint={960}>
    <View>
      <Page>
        <Navbar title="Just Drinks Admin"/>
        <BlockTitle>Main</BlockTitle>
        <List>
          <ListItem link="/" view=".view-main" panelClose title="Locations"/>
            <ListItem link="/alcohol/" view=".view-main" panelClose title="Alcohol List"/>
        </List>
      </Page>
    </View>
  </Panel>

  <!-- Your main view, should have "view-main" class -->
  <View main class="safe-areas" url="/" />


  <!-- Popup -->
  <Popup id="my-popup">
    <View>
      <Page>
        <Navbar title="Popup">
          <NavRight>
            <Link popupClose>Close</Link>
          </NavRight>
        </Navbar>
        <Block>
          <p>Popup content goes here.</p>
        </Block>
      </Page>
    </View>
  </Popup>
  <FireBase config={firebaseAppConfig} geoFirestore={true} loadScripts={true}></FireBase>
   <Login title="Login" appUrl={appUrl} on:loggedIn={loggedIn} userAnonymous={true} show={true} required={true}>
    <div slot="headingContent">
    </div>
  </Login>
</App>
<script>
  import { onMount } from 'svelte';

  import {
    f7,
    f7ready,
    App,
    Panel,
    Views,
    View,
    Popup,
    Page,
    Navbar,
    Toolbar,
    NavRight,
    Link,
    Block,
    BlockTitle,
    LoginScreen,
    LoginScreenTitle,
    List, 
    ListItem,
    ListInput,
    ListButton,
    BlockFooter
  } from 'framework7-svelte';

  import routes from '../js/routes';
  import {firebaseAppConfig} from '../js/firebase-config' // App firebase info
  import FireBase from '../components/dts-shared-components/firebase/firebase.svelte'
import Logout from '../components/dts-shared-components/auth/logout.svelte';
  import Login from '../components/dts-shared-components/auth/login.svelte';

  let appUrl="https://admin.justdrinks.app/"


   if(window.location.href.indexOf("localhost") > -1) {
     appUrl="http://localhost:8080/";
   }
 

  // Framework7 Parameters
  let f7params = {
    name: 'Just Drinks Admin', // App name
    theme: 'auto', // Automatic theme detection



    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: {
      path: '/service-worker.js',
    },
  };
  // Login screen demo data
  let username = '';
  let password = '';

  function loggedIn(e){
    console.log("loggedIn")
    console.log("Hello")
  }
  function alertLoginData() {
    f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
      f7.loginScreen.close();
    });
  }
  onMount(() => {
    f7ready(() => {
        if (typeof firebase === 'object'){
          console.log("firebase installed",firebase)
        }else{
          console.log("no firebase!")
        }
      // Call F7 APIs here
    });
  })
</script>