<Page name="detail" on:pageInit={onPageInit}>
  <Navbar title={loc.name} backLink="Back" />

  <Block>
    <Button color="green" fill href={"/wine/loc/" + id + '/'}>Wine List</Button>
  </Block>

  <BlockTitle>Tequila</BlockTitle>
  <BrandSel atype="tequila" lid={id}></BrandSel>

  <BlockTitle>Vodka</BlockTitle>
  <BrandSel atype="vodka" lid={id}></BrandSel>

    <BlockTitle>Gin</BlockTitle>
  <BrandSel atype="gin" lid={id}></BrandSel>

    <BlockTitle>Rum</BlockTitle>
  <BrandSel atype="rum" lid={id}></BrandSel>

  
    <BlockTitle>Whiskey</BlockTitle>
  <BrandSel atype="whiskey" lid={id}></BrandSel>

    
    <BlockTitle>Scotch</BlockTitle>
  <BrandSel atype="scotch" lid={id}></BrandSel>

      <BlockTitle>Bourbon</BlockTitle>
  <BrandSel atype="bourbon" lid={id}></BrandSel>

  <Block strong>
    <ul>
      <li><b>Url:</b> {f7route.url}</li>
      <li><b>Path:</b> {f7route.path}</li>
      <li><b>Hash:</b> {f7route.hash}</li>
      <li><b>Params:</b>
        <ul>
          {#each Object.keys(f7route.params) as key}
            <li><b>{key}:</b> {f7route.params[key]}</li>
          {/each}
        </ul>
      </li>
      <li><b>Query:</b>
        <ul>
          {#each Object.keys(f7route.query) as key}
            <li><b>{key}:</b> {f7route.query[key]}</li>
          {/each}
        </ul>
      </li>
      <li><b>Route:</b> {f7route.route.path}</li>
    </ul>
  </Block>
</Page>


<script>
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
  import { Page, Navbar, Block, Link,List,BlockTitle,Chip,Button} from 'framework7-svelte';
  import BrandSel from '../components/brand-sel.svelte'
import { getBrands} from '../js/core'
import {place} from '../js/store'

let loc=null;

 export let f7route;
  export let f7router;
  let id;

  function loadData(id){

     
  }

$: if ($place){
  loc=$place.data;
}

  function onPageInit(){
      id=f7route.params.id;
    loadData(id);

      console.log("init",id)
  }
</script>