<Page name="alcohol" on:pageInit={onPageInit}>
  <Navbar title="Alcohol List" backLink="Back" />

  <BlockTitle>Tequila</BlockTitle>
    <Block>
    <Button color="green" fill on:click={()=> popupOpened = true} >Add New Brand</Button>
</Block>

    <Popup class="demo-popup" opened={popupOpened} onPopupClosed={() => popupOpened = false}>
        <View>
                <Page>
            <Navbar title="Add new brand">
                <NavRight>
                <Link popupClose>Close</Link>
                </NavRight>
            </Navbar>
            <List form id="form-add-brand">
                <ListItem title="Alcohol" smartSelect smartSelectParams={{closeOnSelect: true}}>
                <select name="alcohol" closeOnSelect={true}>
                    <option value="tequila">Tequila</option>
                    <option value="vodka">Vodka</option>
                    <option value="gin">Gin</option>
                    <option value="whiskey">Whiskey</option>
                    <option value="scotch">Scotch</option>
                    <option value="rum">Rum</option>
                    <option value="bourbon">Bourbon</option>
                </select>
                </ListItem>
                 <ListInput name="name"
                    label="Name"
                    type="text"
                    placeholder="Brand name"
                    info="example: Casamigos"
                    required
                    validate
                    clearButton
                 />
                  <ListInput name="product"
                    label="Product"
                    type="text"
                    placeholder="Product name"
                    info="examples: 'Original' or 'Anejo'"
                    required
                    validate
                    clearButton
                 />
                 <ListButton title="Add brand" color="green" fill on:click={submitBrand} />
            </List>
            </Page>
        
        </View>
    </Popup>

</Page>
<script>
let popupOpened=false;

function submitBrand(e){
    e.preventDefault();
    let valid=f7.input.validateInputs('#form-add-brand');
    if (valid){
        var formData = f7.form.convertToData('#form-add-brand');
        let newBrand=formData.name.toLowerCase().trim();
        newBrand = newBrand.split(' ').join('-');
        let newList=[];
        // Lookup if brand exists
        let dbRef=$firestoreInstance.collection('alcohol-brands').doc(newBrand);
        dbRef.get()
        .then((d)=>{
            if (d.exists){
                let brand=d.data();
                if (brand.list)
                    newList=brand.list;

                console.log("got this brand",brand)
                // For tequila we dont need to add Anejo,Blanco, or reposado product
                if (formData.alcohol == 'tequila'){
                    let product=formData.product.toLowerCase();
                    if (product == 'anejo' || product == 'blanco' || product == 'reposado' || product == 'extra anejo'){
                       formData.product=""; // clear as its default 
                    }else{
                        // Check if already in list.
                        let list=brand.list;
                        let fi=list.findIndex((o)=>{
                            return o.name.toLowerCase() === product
                        })
                        if (fi >= 0){
                            f7.dialog.alert("This product is already in the master list!");
                            return;
                        }
                    }
                }else{
                     let product=formData.product.toLowerCase();
                    let list=brand.list;
                    let fi=list.findIndex((o)=>{
                        return o.name.toLowerCase() === product
                    })
                    if (fi >= 0){
                        f7.dialog.alert("This product is already in the master list!");
                        return;
                    }
                }

            }else{
                if (formData.alcohol == 'tequila'){
                    let product=formData.product.toLowerCase();
                    if (product == 'anejo' || product == 'blanco' || product == 'reposado' || product == 'extra anejo'){
                       formData.product=""; // clear as its default 
                    }
                }
            }
            
            // Add product
            if (formData.product != "") // Could be blank for certain tequila
                newList.push({name: formData.product})
                
            var setWithMerge = dbRef.set({
                name: formData.name,
                type: formData.alcohol,
                list: newList
            }, { merge: true })
            .then(()=>{
                   f7.dialog.alert("The product has been added.");
            })

        })
        console.log(formData)
    }
    
    
}
function onPageInit(){

}
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
  import { f7,Page, Navbar, Block, Link,List,BlockTitle,Chip,Button,Popup,NavRight,ListItem,View,ListInput,ListButton} from 'framework7-svelte';
</script>