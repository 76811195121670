<Page name="wine" on:pageInit={onPageInit}>
  <Navbar title="Wine List" backLink="Back" />

  {#if !loading}
  <List mediaList>
    {#each wineList as w}
        <ListItem title={w.name} subtitle={w.style + ',' + w.from}>
            <div slot="text">
                <p>{w.descr}</p>
            </div>
            <div slot="after">
                '{w.year - 2000}
            </div>
            <div slot="media">
                {#if w.type == 'wine-rose'}
                    <img src="https://img.imageboss.me/justdrinks/height/100/wine-rose.jpg" alt="" width="100" />
                {/if}
            </div>
        </ListItem>
    {/each}
  </List>
  <Block>
  <Button fill color="green" on:click={()=> popupOpened = true}>Add a wine</Button>
  </Block>

  <Popup class="addwine-popup" opened={popupOpened} onPopupClosed={() => popupOpened = false}>
        <View>
                <Page>
            <Navbar title="Add new wine">
                <NavRight>
                <Link popupClose>Close</Link>
                </NavRight>
            </Navbar>
            <List form id="form-add-wine">
                <ListItem title="Style" smartSelect smartSelectParams={{closeOnSelect: true}}>
                <select name="style" closeOnSelect={true}>
                    <option value="Cabernet">Cabernet</option>
                    <option value="Chardonnay">Chardonnay</option>
                    <option value="Merlot">Merlot</option>
                    <option value="Pinot Grigio">Pinot Grigio</option>
                    <option value="Pinot Noir">Pinot Noir</option>
                    <option value="Red Blend">Red Blend</option>
                    <option value="Reisling">Reisling</option>
                    <option value="Rose">Rose</option>
                    <option value="Sauvignon Blanc">Sauvignon Blanc</option>
                    <option value="Syrah">Syrah</option>
                    <option value="Zinfandel">Zinfandel</option>
                </select>
                </ListItem>
                 <ListInput name="name"
                    label="Name"
                    type="text"
                    placeholder="Wine name"
                    info="example: Justin"
                    required
                    validate
                    clearButton
                 />
                  <ListInput
                    name="year"
                    label="Year"
                    type="select"
                    value={year-1}
                    placeholder="Please choose..."
                >
                    {#each yearList as y }
                    <option value={y}>{y}</option>
                    {/each}
                </ListInput>
                   <ListInput name="from"
                    label="From"
                    type="text"
                    placeholder="Where is this wine from?"
                    info="example: Napa"
                    required
                    validate
                    clearButton
                 />
                 <ListInput
                    label="Description"
                    type="textarea"
                    placeholder="A brief description of the wine"
                    name="descr"
                    clearButton
                 >
                 </ListInput>
                 <ListButton title="Add wine" color="green" fill on:click={submitBrand} />
            </List>
            </Page>
        
        </View>
    </Popup>
  {/if}
</Page>
<script>
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
  import { f7,Page, Navbar, Block, Link,List,BlockTitle,Chip,Button,ListItem,Popup,ListInput,View,ListButton,NavRight} from 'framework7-svelte';

let wineList=[
{year: 2016,name:'Bex',style:'Reisling',type: 'wine-reisling', from:'Germany',descr:'Soft, dry light body, lemon cake with a sour apple finish.'},
{year: 2018,name:'DRNK',style:'Rose',type: 'wine-rose', from:'Russian River',descr:'Firm in acidity & texturem layers of strawberry, meyer lemon, dried herbs, and a touch of white pepper.'}
]
let loading=true;
let popupOpened=false;
let year=new Date().getFullYear();
let yearList=[];
let lid;

 export let f7route;

function submitBrand(e){
   e.preventDefault();
   let valid=f7.input.validateInputs('#form-add-wine');
   if (valid){
         var formData = f7.form.convertToData('#form-add-wine');
         console.log(formData)
         let type=`wine-${formData.style.toLowerCase()}`
         type=type.replace(' ','');

         formData.type=type;
         formData.pic=`${type}.jpg`;
         formData.internal=true;
         let id=`wine-${formData.name}-${formData.style}`;
         formData.id=id.toLowerCase();
        let dbRef=$firestoreInstance.collection(`locations/${lid}/drinks`).doc('wine');
        dbRef.get()
        .then((d)=>{
            if (d.exists){
                return d.data()
            }else{
                return {};
            }
        })
        .then((doc)=>{
            if (doc.list){
                doc.list.push(formData)
            }else{
                doc.list=[formData];
            }
            dbRef.set(doc,{merge:true})
            .then(()=>{
                f7.dialog.alert("Your wine has been added.")
            })
        })
   }
}

 function onPageInit(){
  lid=f7route.params.id;
     for(var i=year; i > year-50; i--){
         yearList.push(i);
     }

     f7.preloader.show();
     window.setTimeout(()=>{
         loading=false;
         f7.preloader.hide();
     },1000)

 } 
</script>